
.lcc-main-container{
    margin-left: auto;
    margin-right: auto; 
    font-family: Helvetica, sans-serif;
}

.lcc-main-container-contact{
    margin-left: auto;
    margin-right: auto; 
    margin-bottom: 40px;
    font-family: Helvetica, sans-serif;
}

.lcc-h1 {
    color: #3e465b;
    font-size: 35px;
    line-height: 1.5;
    margin: 0;
    padding: 30px 30px;
}

.lcc-h2 {
    color: #3e465b;
    font-size: 30px;
    line-height: 1.5;
    margin: 0;
    padding: 30px 30px;
}

.lcc-h3 {
    color: #3e465b;
    font-size: 25px;
    line-height: 1.5;
    margin: 0;
    padding: 30px 30px;
}
  
.lcc-p {
    color: #666d71;
    font-size: 1.6em;
    text-align: left;
    padding: 10px 30px;
}

.lcc-p-contact-info {
    color: #666d71;
    font-size: 1.6em;
    text-align: left;
    padding: 0px;
    text-indent: 50px;
}

.lcc-personal-image{
    border: 1px solid;
    padding: 5px;
    width: 350px;
}

.lcc-imgbox{
  
}

.footer-logo{
    height: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.lcc-landing-img{
    margin-top:-100px;
    height: 100vh;
    width: 100vw;
}

.lcc-center-image{
    margin-left: auto;
    margin-right: auto;
}

.lcc-colors{
    secondary: #666d71;
}
  

table {
    border: 2px solid forestgreen;
    width: 800px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
  }
    
th {
   border-bottom: 1px solid black;
}
    
td {
  text-align: center;
}

.lcc_ol{
    list-style: none;
    list-style-type: decimal;
    margin-left: 100px;
}

.lcc_ul{
    list-style: none;
    list-style-type: circle;
    margin-left: 50px;
}

.lcc_sub_ol{
    list-style: none;
    list-style-type: circle;
    margin-left: 50px;
}

.lcc_sub_li{
  color: #666d71;
  font-size: 1.6em;
  text-align: left;
  padding: 10px 10px;
}

.lcc_sub_li_small{
    color: #666d71;
    font-size: 21em;
    text-align: left;
    padding: 10px 10px;
  }


.lcc_sub_sub_li{
    color: #666d71;
    font-size: 1.6em;
    text-align: left;
    padding: 10px 10px;
}  


.image-container {
    display: flex;
    direction: column;
    align-items: center;
    justify-content: center;
 }